<template>
	<v-container fluid class="basecontainer ma-0 pa-0" id="operationsloginlayoutmobile">
		<v-layout wrap v-if="!isQR2faActive">
			<v-flex xs12 class="pui-login__blue-background">
				<v-flex offset-sm4 sm7>
					<div class="ml-5 pui-login__image--mobile"></div>
				</v-flex>
			</v-flex>
			<v-flex xs12>
				<operations-intro-login class="pui-login__form--mobile">
					<template slot="puiLoginEndActions">
						<slot name="puiLoginEndActions" />
					</template>
					<template slot="puiLoginAppActions">
						<slot name="puiLoginAppActions" />
					</template>
				</operations-intro-login>
			</v-flex>
		</v-layout>
		<pui-login-qr-dialog v-else></pui-login-qr-dialog>
		<pui-login-change-password></pui-login-change-password>
	</v-container>
</template>

<script>
import PuiLoginChangePassword from '@Pui9Login/src/components/PuiLoginChangePassword.vue';

export default {
	name: 'OperationsLoginLayoutMobile',
	components: {
		PuiLoginChangePassword
	},
	computed: {
		isQR2faActive() {
			return this.$store.state.session.token && this.$store.state.session.use2fa;
		}
	}
};
</script>

<style>
.pui-login__image--mobile {
	background-image: url('./../../assets/images/login/pui9_back_mobile.png');
	height: 20vh;
	background-color: var(--primarycolor);
}
</style>
