<template>
	<div>
		<operations-intro-layout />
		<!-- Modal for selecting user port -->
		<pui-modal-dialog-form
			:overflow="false"
			:titleText="$t('operationslogin.portauthorityselection')"
			:modelName="dialogModelName"
			:dialogName="dialogNameSelectLoginPort"
			v-model="modalData"
			:onOk="choosePort"
			:onCancel="closeDialog"
		>
			<template slot="message">
				<v-layout wrap>
					<v-flex xs12>
						<pui-select
							id="login-select-port"
							attach="login-select-port"
							v-model="modalData.portAuthority"
							:items="modalData.portAuthorities"
							itemText="name"
							itemValue="id"
							toplabel
							label="$nbsp;"
							required
							noeditable
						></pui-select>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
import { setUserId } from './../../plugins/matomo';
import OperationsIntroLayout from './OperationsIntroLayout.vue';

export default {
	components: { OperationsIntroLayout },
	data() {
		return {
			dialogNameSelectLoginPort: 'selectLoginPort',
			dialogModelName: 'login',
			modalData: {
				portAuthorities: [],
				portAuthority: {
					id: null,
					portauthoritycode: null
				}
			}
		};
	},
	created() {
		this.$store.commit('setAfterLoginFunction', this.afterLogin);
		this.cleanStorage();
	},
	methods: {
		afterLogin(loginInfo, keepSessionAlive) {
			this.$store.commit('setAfterLogoutFunction', this.cleanStorage);
			setUserId(loginInfo.usr);
			if (loginInfo.properties.PORT_AUTHORITIES && loginInfo.properties.PORT_AUTHORITIES.length > 1) {
				let portAuthorities = [];
				loginInfo.properties.PORT_AUTHORITIES.forEach((pa) =>
					portAuthorities.push({
						id: pa.id,
						name: pa.name,
						portauthoritycode: pa.portauthoritycode
					})
				);

				portAuthorities = portAuthorities.reduce((accumulator, current) => {
					if (!accumulator.find((item) => item.id === current.id)) {
						accumulator.push(current);
					}
					return accumulator;
				}, []);
				const data = { loginInfo, keepSessionAlive, portAuthorities };
				if (loginInfo.properties.PORT_AUTHORITY) {
					this.modalData.portAuthority.id = loginInfo.properties.PORT_AUTHORITY.id;
					this.modalData.portAuthority.portauthoritycode = loginInfo.properties.PORT_AUTHORITY.portauthoritycode;

					this.setSessionPortAutho(loginInfo, keepSessionAlive);
					return;
				}
				this.$puiEvents.$emit(`pui-modalDialogForm-${this.dialogNameSelectLoginPort}-${this.dialogModelName}-show`, data);
			} else {
				this.modalData.portAuthority.id =
					loginInfo.properties.PORT_AUTHORITIES &&
					loginInfo.properties.PORT_AUTHORITIES.length > 0 &&
					loginInfo.properties.PORT_AUTHORITIES[0].id;
				/*-this.modalData.port.portlocode =
					loginInfo.properties.PORTS && loginInfo.properties.PORTS.length > 0 && loginInfo.properties.PORTS[0].portlocode;
				this.modalData.port.token =
					loginInfo.properties.PORTS && loginInfo.properties.PORTS.length > 0 && loginInfo.properties.PORTS[0].token;*/
				this.setSessionPortAutho(loginInfo, keepSessionAlive);
			}
		},
		getIsPASession() {},
		choosePort(modalData) {
			this.setSessionPortAutho(modalData.loginInfo, modalData.keepSessionAlive);
			return Promise.resolve(true);
		},
		setSessionPortAutho(loginInfo, keepSessionAlive) {
			window.localStorage.setItem('workingPortAuthorityId', this.modalData.portAuthority.id);
			window.localStorage.setItem('workingPortAuthorityCode', this.modalData.portAuthority.portauthoritycode);
			this.$store.commit('setPortAuthorityId', this.modalData.portAuthority.id);
			this.$store.commit('setPortAuthorityCode', this.modalData.portAuthority.portauthoritycode);
			const url = '/portauthority/setPortAuthorityToSession';
			this.$puiRequests.getRequest(
				url,
				{ portAuthorityId: this.modalData.portAuthority.id },
				(response) => {
					this.isActiveMovements();
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
			//window.localStorage.setItem('workingPortlocode', this.modalData.port.portlocode);
			//window.localStorage.setItem('workingToken', this.modalData.port.token);
			this.setSessionPort(loginInfo);
			this.setLoginInfo(loginInfo, keepSessionAlive);
		},
		isActiveMovements() {
			const url = '/movement/isActive';
			this.$puiRequests.getRequest(
				url,
				{ portAuthorityId: this.modalData.portAuthority.id },
				(response) => {
					window.localStorage.setItem('movementsActive', response.data);
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		},
		getWeatherApiKey() {
			const opts = {
				model: 'puivariable',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'variable',
							op: 'eq',
							data: 'WEATHERAPI_APIKEY'
						},
						{
							field: 'portauthorityid',
							op: 'eq',
							data: this.modalData.portAuthority.id
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data[0].value && response.data.data[0].value !== '') {
					window.localStorage.setItem('weatherKey', response.data.data[0].value);
					this.$store.commit('setWeatherKey', response.data.data[0].value);
				}
			});
		},
		checkIfBerthplannerForecast() {
			const opts = {
				model: 'portauthorityvariable',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'variable',
							op: 'eq',
							data: 'WEATHER_FORECAST_IN_BP'
						},
						{
							field: 'portauthorityid',
							op: 'eq',
							data: this.modalData.portAuthority.id
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data[0].value && response.data.data[0].value !== null) {
					window.localStorage.setItem('weatherForecastInBP', response.data.data[0].value);
					this.$store.commit('setWeatherForecastInBP', response.data.data[0].value);
				}
			});
		},
		async setSessionPort(loginInfo) {
			const ports = [];
			const portslocodes = [];
			const portstoken = [];
			loginInfo.properties.PORTS.forEach((port) => {
				if (port.portauthorityid == this.modalData.portAuthority.id) {
					ports.push(port.id);
					portslocodes.push(port.portlocode);
					if (port.token) {
						portstoken.push(port.token);
					}
				}
			});

			this.$puiRequests.getRequest(
				'/visitstatuspa/getStatusPA',
				{ portAuthorityId: this.modalData.portAuthority.id },
				(response) => {
					if (response.data) {
						window.localStorage.setItem('visitsStatusValues', JSON.stringify(response.data));
						this.$store.commit('setVisitsStatusValues', response.data);
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);

			this.$puiRequests.getRequest(
				'/stopstatuspa/getStatusPA',
				{ portAuthorityId: this.modalData.portAuthority.id },
				(response) => {
					if (response.data) {
						window.localStorage.setItem('stopsStatusValues', JSON.stringify(response.data));
						this.$store.commit('setStopsStatusValues', response.data);
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);

			const opts2 = {
				model: 'technicalnauticalservicestatuspa',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'port_authority_id',
							op: 'eq',
							data: this.modalData.portAuthority.id
						}
					]
				}
			};
			let statusAp = [];
			this.$puiRequests.postRequest('/puisearch', opts2, (response) => {
				if (response.data) {
					statusAp = response.data.data.map((sap) => {
						return sap.tnsstatuscode;
					});
					window.localStorage.setItem('workingTechnicalnauticalservicestatusStatus', statusAp);
				}
			});

			window.localStorage.setItem('workingPortsId', ports);
			window.localStorage.setItem('workingPortsLocodes', portslocodes);
			window.localStorage.setItem('workingPortsToken', portstoken);

			this.getWeatherApiKey();
			this.checkIfBerthplannerForecast();
		},
		setLoginInfo(loginInfo, keepSessionAlive) {
			this.$store.dispatch('puiLoginAfterLogin', {
				loginInfo: loginInfo,
				keepSessionAlive: keepSessionAlive,
				router: this.$router,
				route: this.$route
			});
		},
		cleanStorage() {
			window.localStorage.removeItem('workingPortAuthorityId');
			window.localStorage.removeItem('workingPortAuthorityCode');
			window.localStorage.removeItem('workingPortsId');
			window.localStorage.removeItem('workingTechnicalnauticalservicestatusStatus');
			window.localStorage.removeItem('workingPortsLocodes');
			window.localStorage.removeItem('workingPortsToken');
			window.localStorage.removeItem('stopsStatusValues');
			window.localStorage.removeItem('visitsStatusValues');
			window.localStorage.removeItem('movementsActive');
			window.localStorage.removeItem('weatherKey');
			window.localStorage.removeItem('weatherForecastInBP');

			this.$store.commit('resetBerthPlannerConfiguration');
		},
		closeDialog() {
			this.$store.dispatch('puiLoginDoLogout', {});
			return Promise.resolve(true);
		}
	}
};
</script>

<style>
.remove-margin {
	margin: 0% !important;
}
</style>
