<template>
	<v-container fluid class="ma-0 pa-0" id="operationsloginlayoutdesktop">
		<v-layout class="pui-login__base-layout">
			<v-flex xs12 sm7 md5 lg4 v-if="!isQR2faActive">
				<operations-intro-login>
					<template slot="puiLoginEndActions">
						<slot name="puiLoginEndActions" />
					</template>
					<template slot="puiLoginAppActions">
						<slot name="puiLoginAppActions" />
					</template>
				</operations-intro-login>
			</v-flex>
			<pui-login-qr-dialog v-else></pui-login-qr-dialog>
			<pui-login-change-password></pui-login-change-password>
			<v-flex :class="!isQR2faActive ? 'sm5 md7 lg8' : 'xs12'" class="pui-login__display-area">
				<slot name="puiLoginDisplayArea" />
				<pui-login-display-area v-if="!appLoginDisplayArea"></pui-login-display-area>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import PuiLoginQRDialog from '@Pui9Login/src/components/PuiLoginQRDialog.vue';
import PuiLoginChangePassword from '@Pui9Login/src/components/PuiLoginChangePassword.vue';
import PuiLoginDisplayArea from '@Pui9Login/src/components/PuiLoginDisplayArea';
import OperationsIntroLogin from './OperationsIntroLogin.vue';

export default {
	name: 'PorcontrolLoginLayoutDesktop',
	components: {
		PuiLoginDisplayArea,
		'pui-login-qr-dialog': PuiLoginQRDialog,
		PuiLoginChangePassword,
		OperationsIntroLogin
	},
	data() {
		return {
			appLoginDisplayArea: this.$store && this.$store.state && this.$store.state.global && this.$store.state.global.appLoginDisplayArea
		};
	},
	computed: {
		isQR2faActive() {
			return this.$store.state.session.token && this.$store.state.session.use2fa;
		}
	}
};
</script>
